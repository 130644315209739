import React from 'react';
import { useQuery } from '@apollo/client';
import { Code } from 'react-content-loader';
import { useLocation, useParams } from 'react-router-dom';
import { AdvBanner } from '../../components/AdvBanner';
import { PopularFilters } from '../../components/PopularFilters';
import { ProductsContainer } from '../../components/ProductsContainer';
import { GET_PRODUCTS_FROM_TERM, GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { FiltersMenu } from '../../components/FiltersMenu';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useAppContext } from '../../context';
import { handleFiltering, handleSorting} from '../../helpers';
import { IProduct } from '../../types';

import styled from '@emotion/styled';

export const SearchRoute = () => {
  const {
    value: { isInlineFilters, searchFilters, productsSorting },
  } = useAppContext();

  const { pathname } = useLocation();

  const { storeId = '', termId, harvestId = '' } = useParams();

  const pureTermId = termId?.split('__')[0];

  const { data: productsData, loading: productsLoading } =
    useQuery(GET_PRODUCTS_FROM_TERM,
      {
        variables: { ids: pureTermId }
      }
    );

  const { data: termData, loading: termLoading } =
    useQuery(GET_PRODUCT_CATEGORY_BY_TERM({ termId: pureTermId, }),
      {
        variables: { ids: pureTermId }
      }
    );

  const { FilterVisibility, FilterPosition, AddProductButton, Currency, AllFiltersAlwaysVisible } =
    useConfiguration({
      storeId,
      harvestId,
    });

  const handleProducts = (products: IProduct[]) => {
    const sortProducts = handleSorting({ products, productsSorting });
    const filterProducts = handleFiltering({
      products: sortProducts,
      searchFilters,
    });
    return filterProducts;
  };

  if (!termLoading && !productsLoading && termData) {
    const edges = termData?.getEPogTermListing?.edges;

    const TermBannerImage = edges[0]?.node?.TermBannerImage;
    const TermBannerVideo = edges[0]?.node?.TermBannerVideo?.data?.fullpath;
    const TermBannerProducts = edges[0]?.node?.TermBannerProducts;
    const TermBannerSponsoredBy = edges[0]?.node?.TermBannerSponsoredBy;
    const TermBannerText = edges[0]?.node?.TermBannerText;
    const TermBannerVideoPosition = edges[0]?.node?.TermBannerVideoPosition;
    const PriceRange = edges[0]?.node?.PriceRange;
    const AddToCartPopup = edges[0]?.node?.AddToCartPopup;

    const products = productsData?.getEPogTermListing?.edges
      .map((edge: any) => edge?.node?.Products)
      .flat()
      .filter((item: any) => item);

    const categories = edges.map((edge: any) => edge?.node).flat();

    const brands: any = products.length && [
      ...new Set(products.map((product: IProduct) => product?.Brand)),
    ];

    const productsToRender = handleProducts(products);

    const name =
      termData?.getEPogTermListing?.edges[0]?.node?.Name || pathname.split('sk=')[1];
    const currency = Currency ? Currency : '$';

    const customFilterValues: any = products.length && [
      ...new Set(products.map((product: IProduct) => product?.Brand)),
    ];
    
    return (
      <SearchRouteStyled>
        {TermBannerProducts && (
          <AdvBanner
            TermBannerImage={TermBannerImage}
            TermBannerProducts={TermBannerProducts}
            TermBannerSponsoredBy={TermBannerSponsoredBy}
            TermBannerText={TermBannerText}
            AddProductButton={AddProductButton}
            Currency={currency}
            TermBannerVideo={TermBannerVideo}
            TermBannerVideoPosition={TermBannerVideoPosition}
          />
        )}

        <PopularFilters
          products={productsToRender}
          productsBeforeFiltering={products}
          categories={categories}
          brands={brands}
          customFilterValues={customFilterValues}
          //termId={termId}
        />

        <div className="container">
          {isInlineFilters &&
            FilterVisibility === 'Stick' &&
            FilterPosition === 'Left' && 
            AllFiltersAlwaysVisible != "Visible" && 
            (
              <div className="filter-container">
                <div>
                  <FiltersMenu productsBeforeFiltering={products} />
                </div>
              </div>
            )}

          <ProductsContainer products={productsToRender} name={name} priceRange={PriceRange} addToCartPopup={AddToCartPopup}
            promoBannerData={{ AddProductButton, Currency:currency, TermBannerImage, TermBannerProducts, TermBannerSponsoredBy, TermBannerText }} />

          {isInlineFilters &&
            FilterVisibility === 'Stick' &&
            FilterPosition === 'Right' && 
            AllFiltersAlwaysVisible != "Visible" && 
            (
              <div className="filter-container">
                <div>
                  <FiltersMenu productsBeforeFiltering={products} />
                </div>
              </div>
            )}

          {isInlineFilters &&
          FilterVisibility === 'Stick' &&
            AllFiltersAlwaysVisible == "Visible" && 
            FilterPosition === 'Left' && (
              <div className="filter-container">
              <div>
                <FiltersMenu productsBeforeFiltering={products} />
              </div>
            </div>
            )
          }

          {isInlineFilters &&
          FilterVisibility === 'Stick' &&
            AllFiltersAlwaysVisible == "Visible" && 
            FilterPosition === 'Right' && (
              <div className="filter-container">
              <div>
                <FiltersMenu productsBeforeFiltering={products} />
              </div>
            </div>
            )
          }

        </div>
      </SearchRouteStyled>
    );
  } else {
    return (
      <div style={{ marginTop: 24 }}>
        <Code />
      </div>
    );
  }
};

const SearchRouteStyled = styled.div`
  .container {
    display: flex;
    margin-top: 20px;

    @media (max-width: 767px) {
      display: block;
    }
  }

  .filter-container {
    min-width: 290px;
    padding: 10px;
    @media (max-width: 1000px) {
      min-width: 20%;
    }
    position: relative;
    & > div {
      position: sticky;
    }
  }
`;
