import { gql } from '@apollo/client';

export const PRODUCT_FRAGMENT = gql`
  fragment GenericProduct on object_ePog {
    fullpath
    Brand
    Description
    Gallery {
      image {
        fullpath
      }
    }
    Video {
      data {
        ...on asset {
          fullpath
        }
      }
      poster {
        fullpath
      }
    }
    VideoGalleryPosition
    VideoGallery {
      ...on fieldcollection_VideoGallery {
        Video{
          data {
            ...on asset {
              fullpath
            }
          }
          poster {
            fullpath
          }
        }
        VideoPosition
      }
    }
    key
    Name
    Nutritient
    Featured
    Price
    PricePerUnit
    RRAmount
    RROverall
    RegularPrice
    PromoAmount
    PromoCount
    Short
    Tags
    id
    AddToCartButtonName
    SubCategory
    PromoType
    PromoImage {
      fullpath
    }
    PromoText
    URLPromoCode
    LabelText
    LabelColorCode
    LabelTextColor
    LabelPosition
    Sponsored
    Attribute {
      ... on fieldcollection_Attributes {
        AttrName,
        AttrValue
      }
    }
    CustomFilterValues {
      ... on fieldcollection_AdditionalFilter{
        AdditionalFilterValue
      }
    }
  }
`;

export const PRODUCT_CARD_FRAGMENT_GALLERY = gql`
  fragment GenericProduct on object_ePog {
    fullpath
    Gallery {
      image {
        fullpath
      }
    }
    Brand
    key
    Name
    Nutritient
    Featured
    Price
    PricePerUnit
    RRAmount
    RROverall
    RegularPrice
    PromoAmount
    PromoCount
    id
    AddToCartButtonName
    SubCategory
    PromoType
    PromoImage {
      fullpath
    }
    PromoText
    URLPromoCode
    LabelText
    LabelColorCode
    LabelTextColor
    LabelPosition
    Sponsored
    Attribute {
      ... on fieldcollection_Attributes {
        AttrName,
        AttrValue
      }
    }
    CustomFilterValues {
      ... on fieldcollection_AdditionalFilter{
        AdditionalFilterValue
      }
    }
  }
`;

// zapytanie z samymi wartosciami przydatnymi dla filtrow
export const PRODUCT_CARD_FRAGMENT = gql`
  fragment GenericProduct on object_ePog {
    fullpath
    Brand
    key
    Name
    Price
    PricePerUnit
    RRAmount
    RROverall
    RegularPrice
    PromoAmount
    PromoCount
    id
    AddToCartButtonName
    SubCategory
    PromoType
    PromoImage {
      fullpath
    }
    PromoText
    URLPromoCode
    LabelText
    LabelColorCode
    LabelTextColor
    LabelPosition
    Sponsored
    Attribute {
      ... on fieldcollection_Attributes {
        AttrName,
        AttrValue
      }
    }
    CustomFilterValues {
      ... on fieldcollection_AdditionalFilter{
        AdditionalFilterValue
      }
    }
  }
`;

export const CATEGORY_FRAGMENT = gql`
  ${PRODUCT_FRAGMENT}
  fragment Category on object_ePogTerm {
    key
    id
    fullpath
    Name
    Products {
      ...GenericProduct
    }
  }

  fragment RecursiveCategory on object_ePogTerm {
    ...Category
    children {
      ...Category
      ... on object_ePogTerm {
        children {
          ...Category
          ... on object_ePogTerm {
            children {
              ...Category
              ... on object_ePogTerm {
                children {
                  ...Category
                  ... on object_ePogTerm {
                    children {
                      ...Category
                      ... on object_ePogTerm {
                        children {
                          ...Category
                          ... on object_ePogTerm {
                            children {
                              ...Category
                              ... on object_ePogTerm {
                                children {
                                  ...Category
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
