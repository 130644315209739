import React from 'react';

import styled from '@emotion/styled';

import {
  AddPositionEnum,
  IAddProductButtonFunctionality,
} from '../../types/config';
import { Stepper } from './variants/Stepper';
import { Text } from './variants/Text';
import { TextAndStepper } from './variants/TextAndStepper';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useParams } from 'react-router-dom';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { IProduct } from '../../types';
import IconButton from '@mui/material/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { contentSquarePageEvent, dynataAddToCart } from '../../helpers'
import { ModalTypes } from '../Modals/types';

export const AddToCartButton = ({ type, product, url, popupData }: any) => {
  const { storeId = '', harvestId = '' } = useParams();

  const { AddPosition, AddProductButton } = useConfiguration({
    storeId,
    harvestId,
  });

  const { AddToCartButtonName } = product;

  const {
    dispatch,
    value: { cart },
  } = useAppContext();

  const [newAmount, setNewAmount] = React.useState(0);

  const handleAddToCartSubmit = (e: any) => {
    e && e.preventDefault();
    contentSquarePageEvent('addToCart')
    if (newAmount > 0) {
      dispatch({
        type: actions.ADD_TO_CART,
        payload: { ...product, newAmount },
      });
      dynataAddToCart(url + '_cartadd' + newAmount)
    }

    if (newAmount === 0) {
      dynataAddToCart(url + '_cartadd' + newAmount)
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { type: 'REMOVE_ALL', product },
      });
    }
  };

  const handleIncreaseByOne = () => {
    newAmount >= 1 && setNewAmount(newAmount - 1);
  };

  const handleDecreaseByOne = () => {
    newAmount <= 11 && setNewAmount(newAmount + 1);
  };

  React.useEffect(() => {
    const productInCart = cart?.find(
      (productInCart: IProduct) => product.id === productInCart?.id
    );

    setNewAmount(productInCart?.amount || 0);
  }, [cart, product]);

  const handleAddToCartMenu = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: {
        isModal: true,
        modalType: ModalTypes.addToCartModal,
        modalContent: { product, serpPositions: popupData?.serpPositions },
      },
    });
  }

  const handleAddToCartChange = (type: string) => {
    if (type === 'plus') {
      contentSquarePageEvent('addToCartIncrease')
      dynataAddToCart(url + '_cartadd' + (newAmount + 1))
      dispatch({ type: actions.ADD_TO_CART, payload: { ...product } });
    }
    if (type === 'minus') {
      contentSquarePageEvent('addToCartDecrease')
      if (newAmount <= 0 ){
        dynataAddToCart(url + '_cartadd0')
      } else {
      dynataAddToCart(url + '_cartadd' + (newAmount - 1))
      }
      dispatch({
        type: actions.REMOVE_FROM_CART,
        payload: { product, type: 'REMOVE_ONE' },
      });
    }
  };

  const buttonSelector = () => {
    if (product?.Variants && popupData?.isEnabled === true) {
      return <Text
        AddPosition={AddPosition}
        handleAddToCartChange={handleAddToCartMenu}
        newAmount={newAmount}
        AddProductButton={
          AddToCartButtonName ? AddToCartButtonName : AddProductButton
        }
        isLeadingToPopup={true}
      />;
    }
    switch (type) {
      case IAddProductButtonFunctionality.text:
        return (
          <Text
            AddPosition={AddPosition}
            handleAddToCartChange={handleAddToCartChange}
            handleAddToCartSubmit={handleAddToCartSubmit}
            newAmount={newAmount}
            AddProductButton={
              AddToCartButtonName ? AddToCartButtonName : AddProductButton
            }
          />
        );
      case IAddProductButtonFunctionality.stepper:
        return (
          <Stepper
            AddPosition={AddPosition}
            handleAddToCartChange={handleAddToCartChange}
            newAmount={newAmount}
          />
        );
      default:
        return (
          <TextAndStepper
            AddPosition={AddPosition}
            handleAddToCartSubmit={handleAddToCartSubmit}
            handleIncreaseByOne={handleIncreaseByOne}
            handleDecreaseByOne={handleDecreaseByOne}
            newAmount={newAmount}
            AddProductButton={
              AddToCartButtonName ? AddToCartButtonName : AddProductButton
            }
          />
        );
    }
  };
  return buttonSelector();
};

export const AddButton = styled.div<{ AddPosition?: AddPositionEnum }>`
  display: inline-flex;
  align-items: center;
  background: ${(props: any) => props.theme.productAddToCartBg};
  color: #ffffff;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  border: none;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0em;
  min-height: 24px;
  cursor: pointer;
  button {
    padding: 2px;
  }

  svg {
    font-size: 8px;
  }

  & > span {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0em;
    padding: 0 3px;
  }
`;

export const StepperComponent = ({
  isTextAndStepper,
  handleAddToCartChange,
  newAmount,
  handleIncreaseByOne,
  handleDecreaseByOne,
}: any) => {
  return (
    <>
      <IconButton
        sx={{
          color: '#fff',
          border: '1px solid #fff',
          padding: '3px',
          marginRight: '3px',
        }}
        size="small"
        onClick={(e) => {
          e.preventDefault();
          if (isTextAndStepper) {
            handleIncreaseByOne();
          } else {
            handleAddToCartChange('minus');
          }
        }}
      >
        <FontAwesomeIcon className="expand" icon={faMinus} />
      </IconButton>
      <span>{newAmount}</span>
      <IconButton
        sx={{
          color: '#fff',
          border: '1px solid #fff',
          padding: '3px',
          marginLeft: '3px',
        }}
        size="small"
        onClick={(e) => {
          e.preventDefault();
          if (isTextAndStepper) {
            handleDecreaseByOne();
          } else {
            handleAddToCartChange('plus');
          }
        }}
      >
        <FontAwesomeIcon className="expand" icon={faPlus} />
      </IconButton>
    </>
  );
};
