import React from 'react';

import { ProductContainer, DescriptionContainer, LinkStyled, CustomLabelStyledDiv } from './styles';
import { IProduct } from '../../types';
import { useConfiguration } from '../../hooks/useConfiguration';
import { useNavigate, useParams } from 'react-router-dom';
import {
  IAddProductButtonFunctionality,
  IOptionButtonVisibilty,
  IProductField,
  ProductFieldName,
} from '../../types/config';
import { Rating, Stack } from '@mui/material';
import { AddButton, AddToCartButton } from '../AddToCartButton';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { actions } from '../../context/actions';
import { ModalTypes } from '../Modals/types';
import { useAppContext } from '../../context';
import { useQuery } from '@apollo/client';
import { GET_LOCALIZED } from '../../queries';

interface IPopupData {
  isEnabled: boolean;
  serpPositions?: any;
}

const getProductPrice = (product: IProduct, displayPriceRange: boolean, currency: string) => {
  const regularPrice = product?.RegularPrice;
  const promoPrice = product?.Price;

  if (displayPriceRange && product?.Variants) { //price range enabled
    let prices: number[] = [promoPrice || regularPrice];
    product?.Variants.forEach((variant: IProduct) =>
      prices.push(variant?.Price || variant?.RegularPrice)
    );
    const min = Math.min(...prices);
    const max = Math.max(...prices);
    return min === max ? <div>{currency}{min.toFixed(2)}</div> : <div>{currency}{min.toFixed(2)} - {currency}{max.toFixed(2)}</div>;

  } else { //price range disabled
    return (product.Price && product.Price != 0) ?
      (<div>{currency}{promoPrice.toFixed(2)} <span className='oldPrice'>{currency}{regularPrice.toFixed(2)}</span></div>)
      :
      (<div>{currency}{regularPrice.toFixed(2)}</div>);
  }
}

export const ProductCard = ({
  product,
  onClick,
  url,
  displayPriceRange,
  popupData,
}: {
  product: IProduct;
  onClick: string;
  url: string;
  displayPriceRange?: boolean;
  popupData?: IPopupData;
}) => {
  const navigate = useNavigate();

  const {
    dispatch,
  } = useAppContext();
  const { storeId = '', harvestId = '' } = useParams();
  const {
    Currency,
    ProductFields,
    AddPosition,
    ProductCardBorderType,
    OptionButtonVisibility,
    AddProductButtonFunctionality,
    LanguageVersion,
    SponsoredPosition
  } = useConfiguration({
    storeId,
    harvestId,
  });
  const { data: translation } = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const currency = Currency ? Currency : '$';
  
  const pricePerUnit = product.PricePerUnit && product.PricePerUnit != "0" ? " (" + product.PricePerUnit + ")" : "";

  return (
    <LinkStyled to={onClick}>
      <ProductContainer ProductCardBorder={ProductCardBorderType} SponsoredPosition={SponsoredPosition}>
        <div>
          <img
            src={`${imgSrcByEnv()}${
              product?.Gallery && product?.Gallery[0]?.image?.fullpath
            }`}
            alt="gallery fullpath"
          />
          {product.LabelText ? 
           <CustomLabelStyledDiv LabelColor={product.LabelColorCode} LabelTextColor={product.LabelTextColor} LabelPosition={product.LabelPosition}>
            {product.LabelText}
            </CustomLabelStyledDiv>
           : ''}
        </div>

        <div></div>

        <DescriptionContainer>
          {ProductFields &&
            Object.values(ProductFields).map(
              (value: IProductField, index: number) => {
                const price = product?.Price != 0
                  ? product?.Price?.toFixed(2)
                  : product?.RegularPrice?.toFixed(2);

                  if (product?.Sponsored && value.Name === ProductFieldName.Sponsored) {
                    return (
                      <p className="sponsored" key={index}>
                        {translation?.getLocalization.Sponsored || "Sponsored"}
                      </p>
                    );
                  }  

                if (product?.Brand && value.Name === ProductFieldName.Brand) {
                  return (
                    <p className="brand" key={index}>
                      {product?.Brand}
                    </p>
                  );
                }
                if (value.Name === ProductFieldName['Add To Cart']) {
                  return (
                    <div key={index}>
                      {product?.Variants?.length &&
                        OptionButtonVisibility ===
                        IOptionButtonVisibilty.visible ? (
                        popupData?.isEnabled ?
                          <AddButton
                            AddPosition={AddPosition}
                            onClick={(e) => {
                              e.preventDefault();
                              dispatch({
                                type: actions.TOGGLE_MODAL,
                                payload: {
                                  isModal: true,
                                  modalType: ModalTypes.addToCartModal,
                                  modalContent: { product, serpPositions: popupData?.serpPositions },
                                },
                              });
                            }}
                          >
                            {translation?.getLocalization.Options || "Options"}
                          </AddButton>
                          :
                          <AddButton
                            AddPosition={AddPosition}
                            onClick={() => navigate(onClick)}
                          >
                            {translation?.getLocalization.Options || "Options"}
                          </AddButton>
                      ) : (
                        <>
                          {AddProductButtonFunctionality ===
                            IAddProductButtonFunctionality.textAndStepper && (
                            <AddToCartButton
                              type={
                                IAddProductButtonFunctionality.textAndStepper
                              }
                              product={product}
                              url={url}
                              popupData={popupData}
                            />
                          )}
                          {AddProductButtonFunctionality ===
                            IAddProductButtonFunctionality.stepper && (
                            <AddToCartButton
                              type={IAddProductButtonFunctionality.stepper}
                              product={product}
                              url={url}
                              popupData={popupData}
                            />
                          )}
                          {AddProductButtonFunctionality ===
                            IAddProductButtonFunctionality.text && (
                            <AddToCartButton
                              type={IAddProductButtonFunctionality.text}
                              product={product}
                              url={url}
                              popupData={popupData}
                            />
                          )}
                        </>
                      )}
                    </div>
                  );
                }

                if (product?.Brand && value.Name === ProductFieldName.Brand) {
                  return (
                    <p className="brand" key={index}>
                      {product?.Brand}
                    </p>
                  );
                }

                if (
                  product?.RRAmount &&
                  value.Name === ProductFieldName.Rating
                ) {
                  return (
                    <div className="rating" key={index}>
                      <Stack spacing={1}>
                        <Rating
                          name="half-rating-read"
                          value={product.RROverall}
                          precision={0.5}
                          readOnly
                        />
                      </Stack>
                      <span>({product?.RRAmount})</span>
                    </div>
                  );
                }

                if (product?.Name && value.Name === ProductFieldName.Name) {
                  return (
                    <p className="name" key={index}>
                      {product?.Name}
                    </p>
                  );
                }

                if (value.Name === ProductFieldName.Price) {
                  return (
                    <p className="price" key={index}>
                      {getProductPrice(product, displayPriceRange || false, currency)}
                      <span className="pricePerUnit">
                        {pricePerUnit}
                      </span>
                    </p>
                  );
                }

                if (value.Name === ProductFieldName.PromoContent) {
                  if (!product.PromoImage) {
                    return (
                      <div className="promo" key={index}>
                        <p>{product.PromoText}</p>
                      </div>
                    );
                  }
                  if (product.PromoImage) {
                    return (
                      <div className="promo" key={index}>
                        <img
                          src={`${imgSrcByEnv()}${
                            product?.PromoImage && product?.PromoImage.fullpath
                          }`}
                          alt="promo information"
                        />
                        <p>{product.PromoText}</p>
                      </div>
                    );
                  }
                }
                return '';
              }
            )}
        </DescriptionContainer>
      </ProductContainer>
    </LinkStyled>
  );
};
