import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_PRODUCT_CATEGORY_BY_TERM } from '../../queries';
import { actions } from '../../context/actions';
import { useAppContext } from '../../context';
import { ModalTypes } from '../Modals/types';
import { Chip } from '../Chip';
import { IProduct } from '../../types';
import { useFilters } from './useFilters';
import {
  FiltersStyled,
  Header,
  PopularFiltersStyled,
  ChipsContainer
} from './styles';
import { useNavigate, useParams } from 'react-router-dom';
import 'swiper/css';
import { ReactSVG } from 'react-svg';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { contentSquarePageEvent } from '../../helpers';
import { useConfiguration } from '../../hooks/useConfiguration';
import { PillFiltersComponent } from './PillFilters';
import { PillFiltersWithImageComponent } from './PillFiltersWithImage';

export const PopularFilters = ({
  products,
  categories,
  brands,
  productsBeforeFiltering,
  customFilterValues
}: {
  products: IProduct[];
  productsBeforeFiltering: IProduct[];
  categories: any;
  brands: string[];
  customFilterValues: string[];
}) => {
  const {
    value: { productsSorting, searchFilters },
    dispatch,
  } = useAppContext();

  const { isPriceFilter, isBrandsFilter, isCategoriesFilter, isSpecialOffersFilter, isCustomFilter, handleFiltersVisibility,
    getCustomizedFilterData, translation } = useFilters({ products, productsBeforeFiltering, });

  const navigate = useNavigate();
  const { storeId = '', termId, harvestId = '' } = useParams();

  const { data, loading } = useQuery(
    GET_PRODUCT_CATEGORY_BY_TERM({
      termId: termId?.split('__')[0],
    }),
    {
      variables: {
        ids: termId?.split('__')[0],
      },
    }
  );
  
  const {
    AllFiltersEnable,
    SortByEnable,
    FilterType,
    PillFiltersWithImageDisplayStyle
  } = useConfiguration({
    storeId,
    harvestId,
  });

  const brandFilter = searchFilters.find(
    (filter: any) => filter.name === 'Brand'
  );

  const ratingFilter = searchFilters.find(
    (filter: any) => filter.name === 'Rating'
  );

  const variantsFilter = searchFilters.find(
    (filter: any) => filter.name === 'Variants'
  );

  const edges = data?.getEPogTermListing?.edges;

  let filters = edges
    .map((edge: any) => edge?.node?.PillFilters)
    .flat()
  let filtersWithImage = edges
    .map((edge: any) => edge?.node?.PillFiltersWithImage)
    .flat()

  return (
    <FiltersStyled>
      {products.length ? <Header>{translation?.getLocalization.PopularFilters}</Header> : ''}
      <PopularFiltersStyled isLessThanSixItems={categories?.length}>
        {categories?.map((category: any, index: number) => (
          (category?.TermImage?.fullpath) ? 
          <div
            className="filter"
            key={index}
            onClick={() => 
              navigate(
                `/${storeId}/${harvestId}/category/${
                  category.id
                }__${category.key.replaceAll(' ', '_')}`
              )
            }
          >
            <img
              src={
                category?.TermImage?.fullpath
                  ? `${imgSrcByEnv()}${category?.TermImage?.fullpath}`
                  : '/files/generic/img/square.svg'
              }
              alt="carousel"
            />
            {/* <img src="/files/generic/img/square.svg" alt="" /> */}
            <p> {category?.key}</p>
          </div> : ''
        ))}
      </PopularFiltersStyled>

      {(products.length || (!products?.length && searchFilters?.length)) &&
        filters.length <= 1 ? (
        <ChipsContainer>
          {AllFiltersEnable != false ?
            <Chip
              title={translation?.getLocalization.AllFilters}
              addinitonalIcon={
                <ReactSVG src="/files/generic/img/allFilters.svg" />
              }
              onClick={() => {
                handleFiltersVisibility();
                contentSquarePageEvent('allFilters')
              }}
              isExpand={false}
              background="#DBDBDB"
            />
            : ""}
          {SortByEnable != false ?
            <Chip
              title={translation?.getLocalization.SortBy}
              addinitonalIcon={<ReactSVG src="/files/generic/img/sortBy.svg" />}
              colors={productsSorting.length ? 'dark' : 'light'}
              onClick={() => {
                contentSquarePageEvent('sortByFilter')
                dispatch({
                  type: actions.TOGGLE_MODAL,
                  payload: {
                    isModal: true,
                    modalType: ModalTypes.sorting,
                  },
                });
              }}
              isExpand={false}
              background="#DBDBDB"
            />
            : ""}
          <Chip
            title={translation?.getLocalization.Price}
            colors={isPriceFilter ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('priceFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.priceRange,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />
          <Chip
            title={translation?.getLocalization.Rating}
            colors={ratingFilter?.value ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('ratingFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.rating,
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />
          <Chip
            title={translation?.getLocalization.Variants}
            colors={variantsFilter?.value ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('variantFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.variants,
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />

          <Chip
            title={translation?.getLocalization.Categories}
            onClick={() => {
              contentSquarePageEvent('categoriesFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.categories,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            colors={isCategoriesFilter ? 'dark' : 'light'}
            isExpand={false}
            background="#ffffff"
          />

          <Chip
            title={translation?.getLocalization.SpecialOffers}
            colors={isSpecialOffersFilter ? 'dark' : 'light'}
            onClick={() => {
              contentSquarePageEvent('specialOffersFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.specialOffers,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            isExpand={false}
            background="#ffffff"
          />

          <Chip
            title={translation?.getLocalization.Brand}
            onClick={() => {
              contentSquarePageEvent('brandSelectFilter')
              dispatch({
                type: actions.TOGGLE_MODAL,
                payload: {
                  isModal: true,
                  modalType: ModalTypes.brands,
                  modalContent: { productsBeforeFiltering },
                },
              });
            }}
            colors={isBrandsFilter ? 'dark' : 'light'}
            isExpand={false}
            background="#ffffff"
          />

          {brands?.length > 1 &&
            brands?.map((brand: string) => {
              const isChecked = brandFilter?.brands[brand] || false;
              return (
                <Chip
                  key={brand}
                  title={brand}
                  onClick={() => {
                    contentSquarePageEvent('brandFilter_' + brand)
                    dispatch({
                      type: actions.ADD_SEARCH_FILTER,
                      payload: {
                        name: 'Brand',
                        brands: { ...brandFilter?.brands, [brand]: !isChecked },
                      },
                    });
                  }}
                  colors={isChecked ? 'dark' : 'light'}
                  isExpand={false}
                  background="#ffffff"
                />
              );
            })}

        </ChipsContainer>
      ) : ((FilterType === 'All' || FilterType === 'Plain') &&
        <PillFiltersComponent filters={filters} getCustomizedFilterData={getCustomizedFilterData} handleFiltersVisibility={handleFiltersVisibility}
          AllFiltersEnable={AllFiltersEnable} SortByEnable={SortByEnable} />
      )
      }

      {(products.length || (!products?.length && searchFilters?.length)) &&
        (FilterType == "Image" || FilterType == "All") &&
        (PillFiltersWithImageDisplayStyle === "WithoutImages" ?
          <PillFiltersComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} squareFilterStyling={true}
            handleFiltersVisibility={handleFiltersVisibility} /> :
          <PillFiltersWithImageComponent filters={filtersWithImage} getCustomizedFilterData={getCustomizedFilterData} />
        )
      }
    </FiltersStyled>
  );
};
