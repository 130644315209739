import React from 'react';

import styled from '@emotion/styled';

import { useQuery } from '@apollo/client';
import { useNavigate, useParams } from 'react-router-dom';
import { useConfiguration } from '../../hooks/useConfiguration';
import { IProduct } from '../../types';
import Pagination from '@mui/material/Pagination';
import { ProductCard } from '../ProductCard';
import { Header } from '../PopularSections/styles';
import { useAppContext } from '../../context';
import { GET_LOCALIZED } from '../../queries'
import { normalizeNameForUrl, contentSquereCustomTag, artificialPageview } from '../../helpers';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { AdvBanner } from '../AdvBanner';
import { TCurrency } from '../../types/config';

export const ProductsContainer = ({
  products,
  name,
  promoBannerData,
  priceRange,
  addToCartPopup
}: {
  products: IProduct[];
  name: string;
  promoBannerData?: {
    TermBannerImage: { fullpath: string };
    TermBannerProducts: IProduct[];
    TermBannerSponsoredBy: string;
    TermBannerText: string;
    AddProductButton?: string;
    Currency: TCurrency;
  };
  priceRange?: string;
  addToCartPopup?: string;
}) => {
  const {
    value: { isInlineFilters },
  } = useAppContext();

  const {
    storeId = '',
    categoryId,
    termId,
    pageNr,
    harvestId = '',
  } = useParams();

  const navigate = useNavigate();

  const { Columns, Rows, LanguageVersion } = useConfiguration({
    storeId,
    harvestId,
  });
  const currentPage = Number(pageNr) || 1;
  const [productLength, setProductLength] = React.useState(0);
  let itemsPerPage = Columns === 3 ? 9 : 16;

  if(Rows && Columns) {
    itemsPerPage = Columns * Rows
  }

  const {data: translation} = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );
  const displayPriceRange = (priceRange === "priceRange") ? true : false;
  const addToCartPopupEnabled = (addToCartPopup === "enabled") ? true : false;
  let serpPositions: any[]=[];

  const handlePaginationChange = (event: any, value: any) => {
    navigate(
      categoryId
        ? `/${storeId}/${harvestId}/category/${categoryId}/${value}`
        : `/${storeId}/${harvestId}/search/${termId}/${value}`
    );
  };

  React.useEffect(() => {
    setProductLength(products.filter((item) => item).length);
  }, [products]);

  React.useEffect(() => {
    if (window.location.pathname.includes('category')) {
    contentSquereCustomTag(1,'Path', window.location.pathname + window.location.hash.replace('#', '?__'), '')
    contentSquereCustomTag(2,'pageType','Category','')
    artificialPageview(window.location.pathname + window.location.hash.replace('#', '?__'))
    } 
    if (window.location.pathname.includes('search')) {
    contentSquereCustomTag(1,'Path', window.location.pathname + window.location.hash.replace('#', '?__'), '')
    contentSquereCustomTag(2,'pageType','SERP','')
    artificialPageview(window.location.pathname + window.location.hash.replace('#', '?__'))
    }
  }, [name])

  return (
    <ProductsContainerStyled
      Columns={Columns}
      isInlineFilters={isInlineFilters}
      count={productLength}
    >
      <Header>
        {productLength} {translation?.getLocalization.ResultsFor} {name}
      </Header>

      <div className="products">
        {products.length > 0 &&
          products
            .slice(
              currentPage === 1 ? 0 : currentPage * itemsPerPage - itemsPerPage,
              currentPage * itemsPerPage
            )
            .map((item: IProduct, index: number) => {
              const isPromoBanner = item?.Video?.title === '__promoBannerPlaceholder__';
              let itemIndex = index;

              const price = item?.Price
                ? item?.Price
                : item?.RegularPrice;
              const brand = item?.Brand
                ? item?.Brand
                : '';
              if (isPromoBanner && !products[index - 1]?.fullpath?.includes("Products")) // prev video
                itemIndex = index - 1;
              if (isPromoBanner && !products[index + 1]?.fullpath?.includes("Products")) // next video
                itemIndex = index + 1;

              const position = itemsPerPage * (currentPage - 1) + itemIndex + 1;

              let promoCode;

              if (item.URLPromoCode != null) {
                promoCode = '__promoCode-' + item.URLPromoCode;
              } else {
                promoCode = '__promoCode-';
              }

              let newColumns = 4;
              let cssClass = '';
              if (Columns !== undefined) { newColumns = Columns; }
              if (position === 1 || position % newColumns === 1) { cssClass = 'fullsize'; }

              serpPositions.push({productId: item.id, productPosition: position})

              if (isPromoBanner) {
                return promoBannerData?.TermBannerProducts && <AdvBanner className={cssClass} {...promoBannerData} />
              } else if (!item.fullpath?.includes("Products")) {
                return (
                  <div className={'video '+cssClass}>
                    <video src={`${imgSrcByEnv()}${item.Video?.data.fullpath}`} controls autoPlay muted loop playsInline/>
                  </div>
                )
              } else {

              return (
                item && (
                  <ProductCard
                    key={index}
                    product={item}
                    url={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}_${price}_${position}${promoCode}`
                    }
                    displayPriceRange={displayPriceRange}
                    popupData={{ isEnabled: addToCartPopupEnabled, serpPositions: serpPositions }}
                    onClick={
                      categoryId
                        ? `/${storeId}/${harvestId}/ip/${categoryId}/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}}_${price}_${position}${promoCode}`
                        : `/${storeId}/${harvestId}/ips/${
                            item?.id
                          }__${brand}__${normalizeNameForUrl(
                            item?.Name
                          )}_${price}_${position}${promoCode}`
                    }
                  />
                )
              );
            }
            })}
      </div>
      {productLength ? (
        <div className="pagination">
          <Pagination
            shape="rounded"
            count={Math.ceil(productLength / itemsPerPage)}
            page={currentPage}
            onChange={handlePaginationChange}
            hidePrevButton={currentPage === 1}
            hideNextButton={
              currentPage === Math.ceil(productLength / itemsPerPage)
            }
          />
        </div>
      ) : (
        ''
      )}
    </ProductsContainerStyled>
  );
};

const ProductsContainerStyled = styled.div<{
  Columns?: number;
  isInlineFilters: boolean;
  count?: number;
}>`
@media (max-width: 768px) {
  margin-top: -40px;
}
  .products {
    display: grid;
    justify-content: center;
    grid-template-columns: ${({ Columns }) => `repeat(${Columns}, 1fr)`};
    gap: 16px;

    @media (max-width: 1140px) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 550px) {
      grid-template-columns: 1fr;
    }
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    margin-left: 5px;
  }
   
  .fullsize {
    width: 100%;

    grid-column: 1 / span 4;

     @media (max-width: 1140px) {
      grid-column: auto;
    }
    @media (max-width: 768px) {
      grid-column: 1 / span 2;
    }
    @media (max-width: 550px) {
      grid-column: 1 / span 1;
    }
  }
  .video{
    @media (max-width: 550px) {
      max-width:360px;
    }
    margin-left:auto;
    margin-right:auto;
    video {
      width: 100%;
      padding:26px 0 0 0;
    }
  }

// promo banner styles
.promoBanner {

  &.fullsize {

    @media (min-width: 1140px),
    (min-width: 550px) and (max-width: 768px) {
      margin-bottom: 26px;
    }

    @media (min-width: 768.5px) and (max-width: 1140px) {
      padding: 15px;
      flex-direction: column;
      gap: 0;
      align-items: start;
      text-align: center;
      margin: 0;
      overflow: auto;

      @media (min-width: 768.5px) {
        margin: 10px 0;
      }

      .products-container {
        display: flex;
        gap: 15px;
        width: 100%;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
      }

      .promoBannerBrandContainer {
        width: 100%;
      }

      .promoBannerProduct {
        text-align: left;
        flex-direction: column;
        gap: 5px;

        &>div>img {
          object-fit: contain;

          &:hover {
            cursor: pointer;
          }

          margin-bottom: 5px;
          width: 90px;
          height: 90px;
        }

        &>div:first-of-type {
          max-width: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  &:not(.fullsize) {
    @media (min-width: 550px) {
      padding: 15px;
      flex-direction: column;
      gap: 0;
      align-items: start;
      text-align: center;
      margin: 0;
      overflow: auto;

      @media (min-width: 768.5px) {
        margin: 10px 0;
      }

      .products-container {
        display: flex;
        gap: 15px;
        width: 100%;
        justify-content: flex-start;
        overflow-x: auto;
        overflow-y: hidden;
        flex-wrap: nowrap;
      }

      .promoBannerBrandContainer {
        width: 100%;
      }

      .promoBannerProduct {
        text-align: left;
        flex-direction: column;
        gap: 5px;

        &>div>img {
          object-fit: contain;

          &:hover {
            cursor: pointer;
          }

          margin-bottom: 5px;
          width: 90px;
          height: 90px;
        }

        &>div:first-of-type {
          max-width: 140px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
      }
    }
  }

  @media (max-width: 550px) {
    max-width: 360px;
    margin: 0 auto;
    margin-bottom: 26px;
  }
}
`;
