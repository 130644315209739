import React from 'react';

import { IProduct } from '../../../types';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled as MuiStyled } from '@mui/material/styles';
import { Variant, VariantsSelectorContainer } from './styles';


export const VariantsSelector = ({
  variantsList,
  selectedVariant: product,
  setSelectedVariant
}: {
  variantsList: IProduct[];
  selectedVariant: IProduct;
  setSelectedVariant: Function;
}) => {
  const variantClickHandler = (clickedVariant: IProduct) => {
    setSelectedVariant(clickedVariant);
  }

  return (
    <>
      {product?.Attribute[0].AttrName &&
        product?.Attribute[0].AttrValue != 'value' && (
          <VariantsSelectorContainer>
            {product?.Attribute.map((attribute, index) => {
              return (
                <div className="variants-inner-container" key={index}>
                  <p className='attrName'>
                    {attribute.AttrName}:
                  </p>
                  <div className="variants" key={index}>
                    {variantsList.map(
                      (variant: IProduct, variant_index: number) => {
                        if (
                          variant?.Attribute[index]?.AttrName &&
                          variant?.Attribute[index]?.AttrValue != 'value' /*&&
                          product?.Attribute[index].AttrValue !=
                          variant?.Attribute[index]?.AttrValue*/
                        ) {
                          return (
                            <Variant
                              className={
                                product?.id === variant?.id ? 'active' : ''
                              }
                              key={variant?.id}
                              onClick={() => variantClickHandler(variant)}
                            >
                              <CustomizedoTooltip title={variant?.Name}>
                                {variant?.Attribute[index]?.AttrValue !=
                                  'value' ? (
                                  <p>
                                    {variant?.Attribute[index]?.AttrName}:{' '}
                                    {variant?.Attribute[index]?.AttrValue}
                                  </p>
                                ) : (
                                  <p>variant_{variant_index}</p>
                                )}
                              </CustomizedoTooltip>
                            </Variant>
                          );
                        } else {
                          return '';
                        }
                      }
                    )}
                  </div>
                </div>
              );
            })}
          </VariantsSelectorContainer>
        )}
    </>

  );
};


const CustomizedoTooltip = MuiStyled(
  ({ className, title, icon, children }: any) => (
    <Tooltip title={title} classes={{ popper: className }}>
      {children}
    </Tooltip>
  )
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
}));