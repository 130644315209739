import React from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useAppContext } from '../../context';
import { actions } from '../../context/actions';
import { useConfiguration } from '../../hooks/useConfiguration';
import { GET_LOCALIZED } from '../../queries';
import { AddToCartModalStyled } from './styles';
import { imgSrcByEnv } from '../../../config/imgSrcByEnv';
import { IProduct } from '../../types';
import { normalizeNameForUrl } from '../../helpers';
import { VariantsSelector } from './VariantsSelector';
import { IAddProductButtonFunctionality } from '../../types/config';
import { AddToCartButton } from '../AddToCartButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type Product = {
  productId?: string;
  productPosition?: number;
};

const findProductPositionById = (products: Product[], productId: string): number | null => {
  for (const product of products) {
    if (product.productId === productId) {
      return product?.productPosition || null;
    }
  }
  return null;
}

export const AddToCartModal = ({ serpPositions, product }: any) => {
  const [selectedVariant, setSelectedVariant] = React.useState<IProduct>(product as IProduct);

  const {
    dispatch,
  } = useAppContext();
  const { storeId = '', categoryId, harvestId = '' } = useParams();

  const { Currency } = useConfiguration({
    storeId,
    harvestId,
  });
  const currency = Currency ? Currency : '$';
  const price = selectedVariant.Price != 0 ? selectedVariant.Price : selectedVariant.RegularPrice;
  const brand = selectedVariant?.Brand ? selectedVariant?.Brand : '';

  let promoCode;
  if (selectedVariant.URLPromoCode != null) {
    promoCode = '__promoCode-' + selectedVariant.URLPromoCode;
  } else {
    promoCode = '__promoCode-';
  }
  const position = `${findProductPositionById(serpPositions, selectedVariant.id)}-${findProductPositionById(serpPositions, product?.id)}`;

  const { LanguageVersion } = useConfiguration({ storeId, harvestId });

  const { data: translation } = useQuery(
    GET_LOCALIZED,
    {
      variables: {
        defaultLanguage: LanguageVersion,
      },
    }
  );

  const handleClose = () => {
    dispatch({
      type: actions.TOGGLE_MODAL,
      payload: { isModal: false, modalContent: null },
    });
  };

  return (
    <AddToCartModalStyled>
      <FontAwesomeIcon className={"closeModal"} onClick={handleClose} icon={faTimes} />
      <h1>{translation?.getLocalization.Variants}:</h1>

      <img
        src={`${imgSrcByEnv()}${selectedVariant.Gallery?.[0].image.fullpath}`}
        alt="gallery fullpath"
        className="prodImage"
      />
      <h2>{selectedVariant?.Name}</h2>
      <p className='price'>{currency}{price}</p>
      <VariantsSelector variantsList={product?.Variants} selectedVariant={selectedVariant} setSelectedVariant={setSelectedVariant} />

      <div className='addToCart'>
        <AddToCartButton
          type={IAddProductButtonFunctionality.text}
          product={selectedVariant}
          url={
            categoryId
              ? `/${storeId}/${harvestId}/ip/${categoryId}/${selectedVariant?.id
              }__${brand}__${normalizeNameForUrl(
                selectedVariant?.Name
              )}}_${price}_${position}${promoCode}`
              : `/${storeId}/${harvestId}/ips/${selectedVariant?.id
              }__${brand}__${normalizeNameForUrl(
                selectedVariant?.Name
              )}_${price}_${position}${promoCode}`
          }
        />
      </div>
    </AddToCartModalStyled >
  );

};
